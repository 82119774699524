import {useMemo, useCallback} from 'react';

export enum APIS {
  AUTH_ME = 'v2/auth/me',
  LOGOUT = 'v2/auth/logout',
  PROFILE = 'v3/profile',
  STORES = 'v2/stores',
  ORDERS = 'v1/orders',
  V2_ORDERS = 'v2/orders',
  ORDERS_PURCHASES = 'v1/orders/purchases',
  FEEDBACK = 'v1/feedback',
  PERSONAS_SEARCH = 'v2/personas/search',
  TASKS_TASKID = `v1/tasks/:taskId`,
  PERSONAS_INDIVIDUALS_SEARCH = 'v1/personas/individuals/search',
  V2_PERSONAS_INDIVIDUALS_SEARCH = 'v2/personas/individuals/search',
  PERSONAS_CUSTOMERS = 'v2/personas/customers',
  MY_ACCOUNT_COMMON_BANNERS = 'v1/common/banners',
  MY_ACCOUNT_COMMON_COUNTRIES = 'v1/common/banners/:banner/countries',
  STORES_CURRENT_STOREID = 'v2/stores/current/:storeId',
  V2_STORES_STOREID_MEMBERS = 'v2/stores/:storeId/members',
  STORES_STOREID_YOURTEAM = 'v2/stores/:storeId/yourteam',
  STORES_STOREID_CAMPAIGNS = 'v1/stores/:storeId/campaigns',
  STORES_STOREID_CAMPAIGNS_TASKS_CARDS = 'v1/stores/:storeId/campaigns/tasks/cards',
  STORES_STOREID_CAMPAIGNS_CAMPAIGNCODE_DETAIL = 'v1/stores/:storeId/campaigns/:campaignCode/detail',
  STORES_STOREID_CAMPAIGNS_CAMPAIGNCODE_HEADER = 'v1/stores/:storeId/campaigns/:campaignCode/header',
  STORES_STOREID_CAMPAIGNS_CAMPAIGNCODE_TASKS = 'v1/stores/:storeId/campaigns/:campaignCode/tasks',
  STORES_STOREID_TASKS = 'v3/stores/:storeId/tasks',
  STORES_STOREID_TASKS_TASKID_PURCHASES = 'v1/stores/:storeId/tasks/:taskId/purchases',
  STORES_STOREID_TASKS_TASKID_SCRIPT = 'v1/stores/:storeId/tasks/:taskId/script',
  STORES_STOREID_TASKS_CAMPAIGNS = 'v3/reports/stores/:storeId/tasks/campaigns',
  STORES_STOREID_TASKS_CAMPAIGNS_HEADER = 'v3/reports/stores/:storeId/tasks/campaigns/header',
  STORES_STOREID_TASKS_TASKID_QUOTES = 'v1/stores/:storeId/tasks/:taskId/quotes',
  V2_STORES_STOREID_TASKS_TASKID_APPOINTMENT = 'v2/stores/:storeId/tasks/:taskId/appointment',
  V1_STORES_STOREID_TASKS_TASKID_APPOINTMENT_HISTORY = 'v1/stores/:storeId/tasks/:taskId/appointment/history',
  V2_STORES_STOREID_TASKS_TASKID_CUSTOMER_APPOINTMENTS = 'v2/stores/:storeId/tasks/:taskId/customer/appointments',
  V2_STORES_STOREID_TASKS_TASKID_CUSTOMER_APPOINTMENTS_DISASSOCIATE = 'v2/stores/:storeId/tasks/:taskId/customer/appointments/disassociate',
  V2_STORES_STOREID_TASKS_TASKID_CUSTOMER_APPOINTMENTS_SEARCH = 'v2/stores/:storeId/tasks/:taskId/customer/appointments/search',
  STORES_STOREID_TASKS_KPI = 'v1/stores/:storeId/tasks/kpi',
  STORES_STOREID_TASKS_ASSIGNTOTASK = 'v1/stores/:storeId/tasks/assignToTask',
  STORES_STOREID_TASKS_ASSIGNTOME = 'v1/stores/:storeId/tasks/assignToMe',
  V2_STORES_STOREID_TASKS_TASKID_FEEDBACK = 'v2/stores/:storeId/tasks/:taskId/feedback',
  V2_CONFIGS_CAMPAIGNCATEGORY_CAMPAIGNCATEGORY_FEEDBACK = 'v2/configs/campaignCategory/:taskCategory/feedback',
  LOYALTY_CUSTOMER_INFO = 'v1/loyalty/customerinfo',
  LOYALTY_BANNERS = 'v1/loyalty/common/banners',
  LOYALTY_COUNTRIES = 'v1/loyalty/common/banners/:banner/countries',
  LOYALTY_EMAIL = 'v1/loyalty/customerinfo/card-recovery',
  V1_LOYALTY_CUSTOMERINFO_SUBSCRIBE = 'v1/loyalty/customerinfo/subscribe',
  LOYALTY_CUSTOMER_BALANCE_TIER = 'v1/loyalty/tiers/info',
  V1_LOYALTY_TIERS_LIST = 'v1/loyalty/tiers/list',
  LOYALTY_CUSTOMER_BALANCE_UPDATE = 'v1/loyalty/tiers/register',
  LOYALTY_GET_REASONS = 'v1/loyalty/tiers/reasons',
  LOYALTY_TIER_INFO = 'v1/loyalty/tiers/customer',
  LOYALTY_POINTS_HISTORY = 'v1/loyalty/points/history',
  LOYALTY_EXPORT_POINTS_HISTORY = 'v1/loyalty/points/history/export',
  LOYALTY_POINTS_HISTORY_ACTIVITIES = 'v1/loyalty/points/event-types',
  LOYALTY_BENEFITS_HISTORY = 'v1/loyalty/benefits/history',
  LOYALTY_EXPORT_REWARDS = 'v1/loyalty/benefits/history/export',
  LOYALTY_GET_REWARDS = 'v1/loyalty/benefits',
  LOYALTY_BENEFITS_SENDEMAIL = 'v1/loyalty/benefits/send-email',
  LOYALTY_GET_REWARDS_STATUS = 'v1/loyalty/benefits/status',
  LOYALTY_BOOK_BOOKING = 'v1/loyalty/benefits/booking',
  LOYALTY_BOOK_BOOKING_BARCODE = 'v1/loyalty/benefits/booking/barcode',
  LOYALTY_BOOK_BOOKING_CLICK = 'v1/loyalty/benefits/booking/click',
  LOYALTY_MISSION_TABLE = 'v2/loyalty/missions/history',
  LOYALTY_EXPORT_MISSION = 'v2/loyalty/missions/history/export',
  LOYALTY_GET_MISSION = 'v1/loyalty/missions',
  LOYALTY_GET_MISSION_STATUS = 'v1/loyalty/missions/status',
  LOYALTY_VERIFY_EMAIL = 'v1/loyalty/customerinfo/verify-email',
  LOYALTY_UNSUSCRIBE = 'v1/loyalty/customerinfo/unsubscribe',
  LOYALTY_RESTORE = 'v1/loyalty/customerinfo/reactivation',
  LOYALTY_CUSTOMER_SUBSCRIPTION = 'v2/loyalty/customerinfo/subscribe',
  REPORTS_CAMPAIGN = 'v3/reports/campaigns',
  REPORTS_CAMPAIGNS_HEADER = 'v3/reports/campaigns/header',
  REPORTS_CAMPAIGNS_FILTER_AREAS = 'v3/reports/campaigns/filter/areas',
  REPORTS_CAMPAIGNS_FILTER_CAMPAIGNS = 'v3/reports/campaigns/filter/campaigns',
  REPORTS_CAMPAIGNS_FILTER_STATUS = 'v3/reports/campaigns/filter/status',
  REPORTS_CAMPAIGNS_FILTER_STORES = 'v3/reports/campaigns/filter/stores',
  REPORTS_STORES_STOREID_CAMPAIGNS = 'v3/reports/stores/:storeId/campaigns',
  REPORTS_STORES_STOREID_CAMPAIGNS_UNASSIGNED = 'v3/reports/stores/:storeId/campaigns/unassigned',
  REPORTS_STORES_STOREID_CAMPAIGNS_HEADER = 'v3/reports/stores/:storeId/campaigns/header',
  REPORTS_STORES_STOREID_CAMPAIGNS_CAMPAIGNCODE_INFO = 'v3/reports/stores/:storeId/campaigns/:campaignCode/info',
  REPORTS_STORES_STOREID_CAMPAIGNS_FILTER_CAMPAIGNS = 'v3/reports/stores/:storeId/campaigns/filter/campaigns',
  REPORTS_STORES_STOREID_CAMPAIGNS_FILTER_ASSIGNEES = 'v3/reports/stores/:storeId/campaigns/filter/assignees',
  PRESCRIPTIONS_PRESCRIPTIONID = 'v1/prescriptions/:prescriptionId',
  V1_QUOTATIONS = 'v1/quotations',
  V1_BENEFITS = 'v1/benefits',
  V1_APPOINTMENTS = 'v1/appointments',
  V1_TASKS_LINKED = 'v1/tasks/linked',
}

export const useApiPath = () => {
  const envPaths = useMemo(() => {
    return (Object.keys(APIS) as (keyof typeof APIS)[]).reduce((acc, key) => {
      const value = APIS[key];
      return {
        ...acc,
        [value]: value,
      };
    }, {} as {[route in APIS]: string});
  }, []);

  const getApiPath = useCallback(
    (api: APIS, payload: {[key: string]: any} = {}): string => {
      let path = envPaths[api];

      Object.keys(payload).forEach(key => {
        const replaceKey = `:${key}`;
        const replaceValue = payload[key];
        path = path.replaceAll(replaceKey, replaceValue);
      });

      return path;
    },
    [envPaths],
  );

  return {
    getApiPath,
  };
};
