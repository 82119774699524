import {useCallback, useMemo} from 'react';

import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';

import {useAPI} from '@/store/api';
import {useApiPath, APIS} from '@/store/api/useApiPath';
import {
  GetAppointmentParamsV2,
  GetAppointmentResponseV2,
  PostAppointmentsParamsV2,
  PostAppointmentsResponse,
  PutDisassociateTaskAppointmentParamsV2,
  GetAppointmentsParamsV2,
  GetAppointmentsResponseV2,
  PatchAppointmentsParamsV2,
  DeleteAppointmentsParamsV2,
  CreateUpdateAppointmentWidgetV2,
  AppointmentV2,
  PostAppointmentHistoryParamsV2,
  PostAppointmentHistoryResponseV2,
} from '@/store/appointments/v2/interfaces';
import {useFeedbackV2, PostFeedbackParamsV2} from '@/store/feedback/v2';

export const useAppointmentsV2 = () => {
  const {api} = useAPI();
  const {getApiPath} = useApiPath();
  const {postFeedback} = useFeedbackV2();

  const getTaskAppointment = useCallback(
    async ({storeId, taskId, banner, country}: GetAppointmentParamsV2) => {
      try {
        const {data} = await api.get<GetAppointmentResponseV2>(
          getApiPath(APIS.V2_STORES_STOREID_TASKS_TASKID_APPOINTMENT, {
            storeId,
            taskId,
          }),
          {
            params: {
              banner,
              country,
            },
          },
        );

        return data;
      } catch (error) {
        Sentry.captureException(error);
        return null;
      }
    },
    [api, getApiPath],
  );

  const postTaskAppointment = useCallback(
    async (
      {
        storeId,
        taskId,
        banner,
        country,
        ...appointment
      }: PostAppointmentsParamsV2,
      taskParams?: PostFeedbackParamsV2,
    ) => {
      try {
        const {data} = await api.post<PostAppointmentsResponse>(
          getApiPath(
            APIS.V2_STORES_STOREID_TASKS_TASKID_CUSTOMER_APPOINTMENTS,
            {
              storeId,
              taskId,
            },
          ),
          {
            ...appointment,
          },
          {
            params: {
              banner,
              country,
            },
          },
        );

        if (taskParams) {
          await postFeedback(taskParams);
        }

        return data;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath, postFeedback],
  );

  const putDisassociateTaskAppointment = useCallback(
    async (
      {
        storeId,
        taskId,
        banner,
        country,
      }: PutDisassociateTaskAppointmentParamsV2,
      taskParams?: PostFeedbackParamsV2,
    ) => {
      try {
        const {data} = await api.put<undefined>(
          getApiPath(
            APIS.V2_STORES_STOREID_TASKS_TASKID_CUSTOMER_APPOINTMENTS_DISASSOCIATE,
            {
              storeId,
              taskId,
            },
          ),
          {},
          {
            params: {
              banner,
              country,
            },
          },
        );

        if (taskParams) {
          await postFeedback(taskParams);
        }

        return data;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath, postFeedback],
  );

  const patchTaskAppointment = useCallback(
    async (
      {
        storeId,
        taskId,
        banner,
        country,
        ...appointment
      }: PatchAppointmentsParamsV2,
      taskParams?: PostFeedbackParamsV2,
    ) => {
      try {
        const {data} = await api.patch<undefined>(
          getApiPath(
            APIS.V2_STORES_STOREID_TASKS_TASKID_CUSTOMER_APPOINTMENTS,
            {
              storeId,
              taskId,
            },
          ),
          {
            ...appointment,
          },
          {
            params: {
              banner,
              country,
            },
          },
        );

        if (taskParams) {
          await postFeedback(taskParams);
        }

        return data;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath, postFeedback],
  );

  const deleteTaskAppointment = useCallback(
    async (
      {storeId, taskId, banner, country}: DeleteAppointmentsParamsV2,
      taskParams?: PostFeedbackParamsV2,
    ) => {
      try {
        const {data} = await api.delete<undefined>(
          getApiPath(
            APIS.V2_STORES_STOREID_TASKS_TASKID_CUSTOMER_APPOINTMENTS,
            {
              storeId,
              taskId,
            },
          ),
          {
            params: {
              banner,
              country,
            },
          },
        );

        if (taskParams) {
          await postFeedback(taskParams);
        }

        return data;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath, postFeedback],
  );

  const getScheduledTaskAppointments = useCallback(
    async ({
      storeId,
      taskId,
      q,
      removeTaskAppointment = false,
      banner,
      country,
    }: GetAppointmentsParamsV2) => {
      try {
        const {data} = await api.post<GetAppointmentsResponseV2>(
          getApiPath(
            APIS.V2_STORES_STOREID_TASKS_TASKID_CUSTOMER_APPOINTMENTS_SEARCH,
            {
              storeId,
              taskId,
            },
          ),
          {
            q,
            removeTaskAppointment,
            bannerSearch: banner,
            countrySearch: country,
          },
        );

        return data;
      } catch (error) {
        Sentry.captureException(error);
        return [];
      }
    },
    [api, getApiPath],
  );

  const formatWidgetData = useCallback(
    (
      widgetResponse: CreateUpdateAppointmentWidgetV2,
      type?: 'CREATE' | 'EDIT' | 'DELETE',
    ): Partial<AppointmentV2> => {
      return {
        ...(type === 'CREATE'
          ? {
              id: widgetResponse?.Exam_Id,
            }
          : {
              searchId: widgetResponse?.Exam_Id,
            }),
        storeCode: widgetResponse?.Store_Search_StoreId,
        firstname: widgetResponse?.Exam_FirstName,
        lastname: widgetResponse?.Exam_LastName,
        type: widgetResponse?.Exam_AppType,
        date: dayjs(widgetResponse?.Exam_AppointmentDate).utc(true).format(),
        email: widgetResponse?.Exam_Email,
        phone: widgetResponse?.Exam_Phone,
        lastUpdate: '',
      };
    },
    [],
  );

  const getAppointmentHistory = useCallback(
    async ({storeId, taskId, ...params}: PostAppointmentHistoryParamsV2) => {
      try {
        const {data} = await api.post<PostAppointmentHistoryResponseV2>(
          getApiPath(APIS.V1_STORES_STOREID_TASKS_TASKID_APPOINTMENT_HISTORY, {
            storeId,
            taskId,
          }),
          {
            ...params,
          },
        );

        return {
          // TODO: Remove this once the API is tested
          nextAppointments: data?.nextAppointments?.reduce(
            (acc, val, index) => {
              if (index === 0) {
                return [
                  ...acc,
                  {
                    ...val,
                    channel: 'TAB_RONA',
                  },
                ];
              }
              return [...acc, val];
            },
            [] as Array<AppointmentV2>,
          ),
          // TODO: Remove this once the API is tested
          pastAppointments: data?.pastAppointments?.reduce(
            (acc, val, index) => {
              if (index === 0) {
                return [
                  ...acc,
                  {
                    ...val,
                    channel: 'TAB_RONA',
                  },
                ];
              }
              return [...acc, val];
            },
            [] as Array<AppointmentV2>,
          ),
        };
      } catch (error) {
        Sentry.captureException(error);
        return {
          nextAppointments: [],
          pastAppointments: [],
        };
      }
    },
    [api, getApiPath],
  );

  return useMemo(
    () => ({
      getTaskAppointment,
      getScheduledTaskAppointments,
      postTaskAppointment,
      putDisassociateTaskAppointment,
      patchTaskAppointment,
      deleteTaskAppointment,
      formatWidgetData,
      getAppointmentHistory,
    }),
    [
      getTaskAppointment,
      getScheduledTaskAppointments,
      postTaskAppointment,
      putDisassociateTaskAppointment,
      patchTaskAppointment,
      deleteTaskAppointment,
      formatWidgetData,
      getAppointmentHistory,
    ],
  );
};
